<template>
  <div>
    <CorporateWallet
      :api-endpoint="'b2e_dashboard/wallet_transaction/list/'"
      :wallet-type="'DEPOSIT'"
    />
  </div>
</template>

<script>
import CorporateWallet from '@/views/accounts/wallet/CorporateWallet.vue'

export default {
  name: 'WalletDeposit',
  components: {
    CorporateWallet,
  },
}
</script>

<style scoped>

</style>
